import {
  useCreateFolderMutation,
  useGetDatabucketsQuery,
  useGetFoldersQuery,
} from '@/api'
import {FolderType} from '@/constants'
import type {Folder, HttpError} from '@/types'
import {Input, Modal, useModal, useToast} from '@gicortex/nucleus'
import {useState} from 'react'
export interface CreateFilesFolderModalProps {
  folder: Folder
  pathPrefix: string
}
export const CreateFilesFolderModal = ({
  folder,
  pathPrefix,
}: CreateFilesFolderModalProps) => {
  const [submitting, setSubmitting] = useState(false)
  const [name, setName] = useState<string | undefined>(undefined)

  const {showSuccessToast, showErrorToast} = useToast()
  const {closeModal} = useModal()

  const {data: folders} = useGetFoldersQuery({type: FolderType.FILES})
  const {data: dataBuckets} = useGetDatabucketsQuery()

  const [createFolder] = useCreateFolderMutation()

  if (!folders || !dataBuckets) {
    return null
  }

  const handleCreate = async () => {
    try {
      setSubmitting(true)
      if (!folder?.groupId) throw new Error('Group ID is required')
      const createFolderModel = {
        bucket: dataBuckets[0].name,
        groupId: folder.groupId,
        path:
          folder.id === folder.groupId ? `/${name}` : `${folder.path}/${name}`,
        type: FolderType.FILES,
      }
      await createFolder({createFolderModel}).unwrap()
      closeModal()
      showSuccessToast('Folder created successfully')
    } catch (error: unknown) {
      if (error instanceof Error) {
        showErrorToast(error.message)
      } else if ((error as HttpError).status) {
        const httpError = error as HttpError
        switch (httpError.status) {
          case 404:
          case 409:
            showErrorToast(httpError.data.detail)
            break
          default:
            showErrorToast('Failed to create folder')
        }
      } else {
        showErrorToast('An unknown error occurred')
      }
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Modal>
      <Modal.Title>Create Folder</Modal.Title>
      <Input
        label='Folder'
        disabled={true}
        name='folder'
        value={
          folder.path.startsWith(`/${pathPrefix}`)
            ? folder.path
            : `/${pathPrefix}${folder.path}`
        }
      />
      <Input
        autoFocus
        name='name'
        label='Name'
        required
        onChange={(e) => setName(e.target.value)}
      />
      {name === '' && (
        <span className='text-red-500 text-xs'>Name is required</span>
      )}
      <Modal.Footer
        onSubmit={handleCreate}
        submitButtonProps={{
          className: 'disabled:bg-red-100 enabled:bg-indigo-100',
          closeOnSubmit: false,
          disabled: name === undefined || name === '' || submitting,
          label: 'Create',
        }}
      />
    </Modal>
  )
}
