import {TAG_TYPE} from '@/constants'
import type {RootState} from '@/types'
import {type FileStatus, api} from '.'

api.enhanceEndpoints({
  endpoints: {
    // chat history
    deleteChatHistory: {invalidatesTags: [TAG_TYPE.CHAT_HISTORY]},
    getChatHistories: {providesTags: [TAG_TYPE.CHAT_HISTORY]},
    // file
    deleteDatabucketFile: {invalidatesTags: [TAG_TYPE.DATA_BUCKET]},
    getDatabucketFiles: {providesTags: [TAG_TYPE.DATA_BUCKET]},
    uploadDatabucketFiles: {
      invalidatesTags: [TAG_TYPE.DATA_BUCKET],
      onQueryStarted: async (
        {dataBucketFileUploadFormMultiPart, databucketName},
        {dispatch, queryFulfilled, getState},
      ) => {
        // cast to FormData to access form methods
        const formData =
          dataBucketFileUploadFormMultiPart as unknown as FormData
        const {current} = getState() as RootState
        const groupName = current.uploadingGroup.name ?? 'Personal'
        const uploadedFiles = formData
          .getAll('files')
          .map((file) => {
            if (!(file instanceof Blob)) return null
            return {
              file: `/${groupName}/${file.name}`,
              path: `/${groupName}/${file.name}`,
              status: 'QUEUED' as FileStatus,
              bucket: databucketName,
            }
          })
          .filter((file) => file !== null)
        const patchResult = dispatch(
          api.util.updateQueryData('getDatabucketFiles', undefined, (draft) => {
            draft.push(...uploadedFiles)
          }),
        )
        queryFulfilled.catch(patchResult.undo)
      },
    },
    // folder
    createFolder: {invalidatesTags: [TAG_TYPE.FOLDER]},
    deleteFolder: {
      invalidatesTags: [TAG_TYPE.FOLDER, TAG_TYPE.FILE, TAG_TYPE.PROMPT],
    },
    getFolders: {providesTags: [TAG_TYPE.FOLDER]},
    updateResourceFolder: {
      invalidatesTags: [
        TAG_TYPE.FOLDER,
        TAG_TYPE.DATA_BUCKET,
        TAG_TYPE.FILE,
        TAG_TYPE.PROMPT,
      ],
    },
    updateResourceFolderInBatch: {
      invalidatesTags: [
        TAG_TYPE.FOLDER,
        TAG_TYPE.DATA_BUCKET,
        TAG_TYPE.FILE,
        TAG_TYPE.PROMPT,
      ],
    },
    // prompt
    createNewPrompt: {invalidatesTags: [TAG_TYPE.PROMPT]},
    deletePromptById: {invalidatesTags: [TAG_TYPE.PROMPT]},
    getAllPrompts: {providesTags: [TAG_TYPE.PROMPT]},
  },
})
