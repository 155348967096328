import {FileProcessingStatus, STORAGE_PROTOCOL} from '@/constants'
import type {DataBucketFile} from '@/types'
import {uuidUtil} from '../uuid'

const removeUuidPrefix = (fileName: string) => {
  const substrings = fileName.split('.')
  return uuidUtil.isUuid(substrings[0])
    ? substrings.slice(1).join('.')
    : fileName
}

const getStatusProps = (status: FileProcessingStatus) => {
  switch (status) {
    case FileProcessingStatus.SUCCESS:
      return {
        color: 'text-green-500',
        description: 'Ready',
        name: FileProcessingStatus.SUCCESS,
      }
    case FileProcessingStatus.FAILURE:
      return {
        color: 'text-red-500',
        description: 'Failed to process',
        name: FileProcessingStatus.FAILURE,
      }
    default:
      return {
        color: 'animate-pulse text-yellow-500',
        description: 'Processing',
        name: FileProcessingStatus.PENDING,
      }
  }
}

const getName = (file: DataBucketFile) => {
  const name = file.file.split('/').pop()
  return name ? removeUuidPrefix(name) : 'untitled'
}

const getExtension = (file: DataBucketFile) => {
  const parts = file.file.split('.')
  return parts.length === 1 ? undefined : parts.pop()
}

const getSourceId = (file: DataBucketFile) => {
  return `${STORAGE_PROTOCOL.AWS}${file.bucket}/${file.path}`
}

const humanReadableFileSize = (size: number) => {
  if (size < 1024) return `${size} B`
  if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`
  if (size < 1024 * 1024 * 1024)
    return `${(size / (1024 * 1024)).toFixed(2)} MB`
  return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`
}

export const fileUtil = {
  getExtension,
  getName,
  getSourceId,
  getStatusProps,
  humanReadableFileSize,
  removeUuidPrefix,
}
