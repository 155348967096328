import {useDeleteFolderMutation} from '@/api'
import type {Folder, HttpError} from '@/types'
import {DeleteModalButton, useModal, useToast} from '@gicortex/nucleus'
import {useState} from 'react'

export interface DeleteFolderButtonProps {
  folder: Folder
}
export const DeleteFolderButton = ({folder}: DeleteFolderButtonProps) => {
  const [deleteFolder] = useDeleteFolderMutation()
  const {showSuccessToast, showErrorToast} = useToast()
  const {closeModal} = useModal()

  const [submitting, setSubmitting] = useState(false)

  const handleDelete = async () => {
    try {
      setSubmitting(true)
      if (!folder.id) {
        throw new Error('Folder ID is missing')
      }
      await deleteFolder({folderId: folder.id}).unwrap()
      closeModal()
      showSuccessToast('Folder deleted successfully')
    } catch (error: unknown) {
      const httpError = error as HttpError
      switch (httpError.status) {
        case 400:
          showErrorToast(httpError.data.detail)
          break
        default:
          showErrorToast('Failed to delete Folder')
      }
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <DeleteModalButton
      className='p-0 hidden group-hover:block'
      content={
        <p>
          Are you sure you want to delete folder <strong>{folder.path}</strong>?
        </p>
      }
      title='Delete Folder'
      tooltip='Delete Folder'
      onSubmit={handleDelete}
      submitButtonProps={{
        children: 'Delete',
        disabled: submitting,
        loading: submitting,
      }}
    />
  )
}
