import {SidebarTab} from '@/constants'
import {Files} from '@/features/files'
import {History} from '@/features/history'
import {Prompts} from '@/features/prompts'
import {PromptsIcon} from '@/icons/prompts'
import {ToggleSidebarButton} from '@/layout/toggle-sidebar-button'
import {currentSlice} from '@/store/current'
import type {RootState} from '@/types'
import {
  IconButton,
  KvLogo,
  Sidebar as NucleusSidebar,
  Row,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  cn,
} from '@gicortex/nucleus'
import {File, HistoryIcon, type LucideIcon} from 'lucide-react'
import {useDispatch, useSelector} from 'react-redux'

interface TabType {
  id: number
  icon: LucideIcon
  label: string
  onClick: () => void
  panel: React.ReactNode
  selected: boolean
}

export const Sidebar = () => {
  const current = useSelector((state: RootState) => state.current)
  const dispatch = useDispatch()

  const tabs = [
    {
      id: 1,
      icon: HistoryIcon,
      label: SidebarTab.HISTORY,
      onClick: () => {
        const action = currentSlice.actions.setSelectedSidebarTab(
          SidebarTab.HISTORY,
        )
        dispatch(action)
      },
      panel: <History />,
      selected: current.selectedSidebarTab === SidebarTab.HISTORY,
    },
    {
      id: 2,
      icon: File,
      label: SidebarTab.FILES,
      onClick: () => {
        const action = currentSlice.actions.setSelectedSidebarTab(
          SidebarTab.FILES,
        )
        dispatch(action)
      },
      panel: <Files />,
      selected: current.selectedSidebarTab === SidebarTab.FILES,
    },
    {
      id: 3,
      icon: PromptsIcon,
      label: SidebarTab.PROMPTS,
      onClick: () => {
        const action = currentSlice.actions.setSelectedSidebarTab(
          SidebarTab.PROMPTS,
        )
        dispatch(action)
      },
      panel: <Prompts />,
      selected: current.selectedSidebarTab === SidebarTab.PROMPTS,
    },
  ]

  return (
    <TabGroup>
      <NucleusSidebar
        appLogo={<KvLogo size='m' />}
        header={<SidebarHeader tabs={tabs} />}
        toggleSidebarButton={<ToggleSidebarButton />}
      >
        <TabPanels className='overflow-y-auto mt-0'>
          {tabs.map((tab) => (
            <TabPanel
              className='!py-0'
              key={tab.id}
            >
              {tab.panel}
            </TabPanel>
          ))}
        </TabPanels>
      </NucleusSidebar>
    </TabGroup>
  )
}

export const SidebarHeader = ({tabs}: {tabs: TabType[]}) => (
  <TabList className='flex justify-between p-2 ml-auto'>
    <Row>
      {tabs.map((tab) => (
        <Tab
          as='div'
          className='focus-visible:outline-none p-0 data-[selected]:border-b-0'
          key={tab.id}
        >
          <Row>
            <IconButton
              className={cn(tab.selected && 'bg-gray-200')}
              icon={tab.icon}
              size='m'
              tooltip={tab.label}
              onClick={tab.onClick}
            />
          </Row>
        </Tab>
      ))}
    </Row>
  </TabList>
)
