import {
  useCreateDatabucketFileIndexMutation,
  useGetDatabucketFilesQuery,
} from '@/api'
import {currentSlice} from '@/store/current'
import type {RootState} from '@/types'
import {asyncUtil} from '@/utils/async'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

export const useReprocessFiles = () => {
  const {reprocessFiles} = useSelector((state: RootState) => state.current)
  const dispatch = useDispatch()
  const {data: files = [], refetch} = useGetDatabucketFilesQuery()
  const [retriggerFile] = useCreateDatabucketFileIndexMutation()

  useEffect(() => {
    const initialFailedFiles = files.filter((file) => file.status !== 'SUCCESS')
    if (
      reprocessFiles.hasAttemptedReprocessing ||
      initialFailedFiles.length === 0
    ) {
      return
    }
    const triggerAndFetchFiles = async ({
      attempts,
      delay = 5000,
    }: {attempts: number; delay?: number}) => {
      dispatch(currentSlice.actions.setReprocessFiles({isReprocessing: true}))
      for (const file of initialFailedFiles) {
        await retriggerFile({
          databucketName: file.bucket,
          dataBucketFileIndexRequest: {path: file.path},
        })
      }

      for (let attempt = 1; attempt <= attempts; attempt++) {
        await asyncUtil.sleep(delay)
        const {data: files = []} = await refetch()
        const hasFilesToReprocess = files.some(
          (file) => file.status !== 'SUCCESS',
        )
        const isFinalAttempt = attempt === attempts || !hasFilesToReprocess
        if (isFinalAttempt) {
          dispatch(
            currentSlice.actions.setReprocessFiles({
              hasAttemptedReprocessing: true,
              isReprocessing: false,
            }),
          )
        }
      }
    }
    triggerAndFetchFiles({attempts: 3, delay: 5000})
  }, [
    files,
    reprocessFiles.hasAttemptedReprocessing,
    refetch,
    retriggerFile,
    dispatch,
  ])
}
