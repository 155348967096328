import {Col, cn} from '@gicortex/nucleus'

export interface SidebarContentProps {
  children: React.ReactNode
  className?: string
}

export const SidebarContent = ({children, className}: SidebarContentProps) => (
  <Col className={cn('p-2 h-[calc(100vh-171px)]', className)}>{children}</Col>
)
