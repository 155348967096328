import {useUploadFiles} from '@/hooks/use-upload-files'
import type {Folder, HttpError} from '@/types'
import {fileUtil} from '@/utils/file'
import {
  Dropzone,
  Form,
  FormModal,
  IconButton,
  nonEmptyString,
  useModal,
  useToast,
} from '@gicortex/nucleus'
import {Minus} from 'lucide-react'
import {useState} from 'react'

export interface UploadFileModalProps {
  folder: Folder
  pathPrefix: string
}
export const UploadFileModal = ({folder, pathPrefix}: UploadFileModalProps) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const [submitting, setSubmitting] = useState(false)

  const {closeModal} = useModal()
  const {showErrorToast} = useToast()
  const {uploadFiles, uploadFilesError, uploadFilesIsLoading} = useUploadFiles()

  const handleDrop = (newFiles: File[]) => {
    setSelectedFiles((existingFiles) => [...existingFiles, ...newFiles])
  }
  const handleSubmit = async () => {
    try {
      setSubmitting(true)
      uploadFiles({files: selectedFiles, folder})
      closeModal()
    } catch (error: unknown) {
      if (error instanceof Error) {
        showErrorToast(error.message)
      } else if ((error as HttpError).status) {
        const httpError = error as HttpError
        switch (httpError.status) {
          default:
            showErrorToast('Failed to create folder')
        }
      } else {
        showErrorToast('An unknown error occurred')
      }
    } finally {
      setSubmitting(false)
    }
  }

  if (uploadFilesError || uploadFilesIsLoading) {
    return null
  }

  const SelectedFiles = () => {
    const handleUnselect = (fileName: string) => {
      setSelectedFiles(selectedFiles.filter((file) => file.name !== fileName))
    }

    return (
      <ul className='flex flex-col gap-2'>
        {selectedFiles.map((file) => (
          <li
            className='bg-gray-100 flex items-center justify-between p-2 rounded'
            key={file.name}
          >
            <div className='max-w-xs'>
              <span className='block overflow-ellipsis overflow-hidden whitespace-nowrap'>
                {file.name}
              </span>
              <small className='text-gray-500'>
                {fileUtil.humanReadableFileSize(file.size)}
              </small>
            </div>
            <IconButton
              icon={Minus}
              onClick={() => handleUnselect(file.name)}
              tooltip='Unselect'
            />
          </li>
        ))}
      </ul>
    )
  }

  return (
    <FormModal
      defaultValues={{folder: folder.name}}
      modalProps={{size: 'm'}}
      onSubmit={handleSubmit}
      schema={() => ({
        folder: nonEmptyString('Folder'),
      })}
      submitButtonProps={{
        className: 'disabled:bg-red-100 enabled:bg-indigo-100',
        disabled: selectedFiles.length === 0 || submitting,
        label: 'Upload',
      }}
      title='Upload File'
    >
      <Form.Input
        label='Folder'
        disabled={true}
        name='folder'
        value={
          folder.path.startsWith(`/${pathPrefix}`)
            ? folder.path
            : `/${pathPrefix}${folder.path}`
        }
      />
      <Dropzone onDrop={handleDrop} />
      {selectedFiles.length !== 0 && <SelectedFiles />}
    </FormModal>
  )
}
