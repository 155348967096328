import {FolderType} from '@/constants'
import type {Entity, Folder, FolderResponse, ResponseEntity} from '@/types'
import {randomUtil} from '../random'

const toTree = ({
  rootFolderName,
  folderResponses = [],
  responseEntities = [],
}: {
  rootFolderName: string
  folderResponses?: FolderResponse[]
  responseEntities?: ResponseEntity[]
}): Folder => {
  const tree: Folder = {
    children: [] as (Folder | Entity)[],
    name: rootFolderName,
    path: `${rootFolderName}/`,
  }

  // create folder tree
  for (const folderResponse of folderResponses) {
    const parts = folderResponse.path.split('/').slice(1)
    let current = tree
    let level = 0
    for (const part of parts) {
      const existing = current.children.find(
        (child) => 'name' in child && child.name === part,
      )
      if (existing) {
        current = existing as Folder
        level++
      } else {
        const folder: Folder = {
          name: part,
          children: [],
          indent: level,
          ...folderResponse,
        }
        current.children.push(folder)
        current = folder
      }
    }
  }

  // populate the folder tree with prompts
  const walkAndInsert = (path: string, item: ResponseEntity) => {
    const parts = path.split('/').slice(1)
    let current = tree
    let level = 0
    for (const part of parts) {
      const existing = current.children.find(
        (child) => 'name' in child && child.name === part,
      )
      if (existing) {
        current = existing as Folder
      } else {
        const folder: Folder = {
          id: randomUtil.uuid(),
          name: part,
          children: [],
          indent: level,
          path,
        }
        current.children.push(folder)
        current = folder
      }
      level++
    }
    current.children.push({...item, indent: level})
  }
  for (const responseEntity of responseEntities) {
    if (responseEntity?.tags) {
      if (responseEntity.tags.length === 0) {
        tree.children.push({...responseEntity, indent: 0})
      } else {
        for (const tag of responseEntity.tags) {
          walkAndInsert(tag.value, responseEntity)
        }
      }
    }
  }

  return tree
}

const isPromptItem = (item: Folder | Entity) => {
  return 'prompt' in item
}

const isFileItem = (item: Folder | Entity) => {
  return 'file' in item
}

const isFileFolder = (item: Folder | Entity) => {
  return 'type' in item && item.type === FolderType.FILES
}

const isPromptFolder = (item: Folder | Entity) => {
  return 'type' in item && item.type === FolderType.PROMPTS
}

export const folderUtil = {
  isFileFolder,
  isFileItem,
  isPromptFolder,
  isPromptItem,
  toTree,
}
