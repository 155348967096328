import {useGetDatabucketsQuery, useUploadDatabucketFilesMutation} from '@/api'
import {currentSlice} from '@/store/current'
import type {Folder} from '@/types'
import {randomUtil} from '@/utils/random'
import {textUtil} from '@/utils/text'
import {useToast} from '@gicortex/nucleus'
import {useDispatch} from 'react-redux'

export const useUploadFiles = () => {
  const dispatch = useDispatch()
  const {showPromiseToast} = useToast()

  const {data: dataBuckets = []} = useGetDatabucketsQuery()

  const [upload, {isLoading: uploadFilesIsLoading, error: uploadFilesError}] =
    useUploadDatabucketFilesMutation()

  const uploadFiles = async ({
    files,
    folder,
  }: {
    files: File[]
    folder: Folder
  }) => {
    const filesWithUniqueIds = files.map((file) => ({
      ...file,
      id: randomUtil.uuid(),
    }))

    try {
      if (!dataBuckets.length) {
        throw new Error('Data Bucket is required to upload files')
      }

      const filesFormData = new FormData()
      for (const file of files) {
        const uniqueFileName = `${randomUtil.uuid()}.${file.name}`
        filesFormData.append('files', file, uniqueFileName)
        if (folder.id) {
          filesFormData.append('folderId', folder.id)
        }
        if (folder.groupId) {
          filesFormData.append('groupId', folder.groupId)
        }
      }

      dispatch(currentSlice.actions.setUploadingFiles(filesWithUniqueIds))

      const uploadPromise = upload({
        databucketName: dataBuckets[0].name,
        // @ts-expect-error rtk query multipart bug
        // ref: https://github.com/reduxjs/redux-toolkit/issues/3063
        dataBucketFileUploadFormMultiPart: filesFormData,
      })

      const fileCount = `${files.length} ${textUtil.pluralize('file', {itemCount: files.length})}`
      showPromiseToast({
        promise: uploadPromise,
        content: {
          loading: `Uploading ${fileCount}...`,
          success: `${fileCount} uploaded successfully`,
          error: `Failed to upload ${fileCount}. Please try again.`,
        },
      })

      return uploadPromise
    } finally {
      dispatch(
        currentSlice.actions.removeUploadingFiles(
          filesWithUniqueIds.map((file) => file.id),
        ),
      )
    }
  }

  return {
    uploadFiles,
    uploadFilesError,
    uploadFilesIsLoading,
  }
}
