import {ButtonGroup} from '@/components/button-group'
import {CreateFolderButton} from '@/components/create-folder-button'
import {DeleteFolderButton} from '@/components/delete-folder-button'
import type {DataBucketFile, Entity, Folder} from '@/types'
import {randomUtil} from '@/utils/random'
import {Col, Icon, Row, cn} from '@gicortex/nucleus'
import {ChevronDown, ChevronRight} from 'lucide-react'
import {useState} from 'react'
import {CreateFilesFolderModal} from './create-files-folder-modal'
import {FileItem} from './file-item'
import {UploadFileButton} from './upload-file-button'

export interface FileFolder {
  className?: string
  folder: Folder
  pathPrefix: string
}
export const FileFolder = ({className, folder, pathPrefix}: FileFolder) => {
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <Col>
      <Row
        className={cn(
          'gap-1 group ml-auto p-1 rounded-lg w-full',
          'hover:bg-indigo-100 hover:cursor-pointer',
          className,
        )}
        onClick={handleClick}
      >
        <Row className='flex-1 gap-1'>
          <Icon component={open ? ChevronDown : ChevronRight} />
          {folder.path?.split('/').pop()}
        </Row>
        <ButtonGroup className='align-center hidden ml-auto pr-1 space-x-2 group-hover:flex [&_button]:p-0'>
          <UploadFileButton
            folder={folder}
            pathPrefix={pathPrefix}
          />
          <CreateFolderButton
            modal={
              <CreateFilesFolderModal
                folder={folder}
                pathPrefix={pathPrefix}
              />
            }
          />
          {folder.isDeletable !== false && (
            <DeleteFolderButton folder={folder} />
          )}
        </ButtonGroup>
      </Row>
      {open &&
        'children' in folder &&
        folder.children?.map((item: Folder | Entity) => (
          <div
            style={{
              // NOTE @simon No idea why `ml-${(item.indent ?? 0) + 1}`
              // doesn't work here
              marginLeft: `${(item.indent ?? 0) + 4}px`,
            }}
            key={item.id ? item.id : randomUtil.uuid()}
          >
            {'children' in item ? (
              <FileFolder
                folder={item as Folder}
                pathPrefix={pathPrefix}
              />
            ) : (
              <FileItem
                file={item as DataBucketFile}
                pathPrefix={pathPrefix}
              />
            )}
          </div>
        ))}
    </Col>
  )
}
