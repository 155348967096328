import type {FileProcessingStatus} from '@/constants'
import {currentSlice} from '@/store/current'
import type {DataBucketFile, RootState} from '@/types'
import {fileUtil} from '@/utils/file'
import {Icon, XIconButton, cn} from '@gicortex/nucleus'
import {Dot} from 'lucide-react'
import {useDispatch, useSelector} from 'react-redux'

export const SelectedFiles = () => {
  const files = useSelector((state: RootState) => state.current.selectedFiles)
  const dispatch = useDispatch()

  const handleUnselectFile = (file: DataBucketFile) => {
    dispatch(currentSlice.actions.removeFile(file))
  }

  return (
    <div className='flex flex-wrap gap-2'>
      {files.map((file) => (
        <div
          className='bg-gray-50 text-gray-700 flex items-center pl-3 rounded-full'
          key={file.path}
        >
          <Icon
            className={cn(
              'mr-1 mt-1 pointer-events-auto',
              fileUtil.getStatusProps(file.status as FileProcessingStatus)
                .color,
            )}
            component={Dot}
            strokeWidth='12'
            tooltip={
              fileUtil.getStatusProps(file.status as FileProcessingStatus)
                .description
            }
          />
          <span className='mr-2 max-w-[100px] sm:max-w-[150px] md:max-w-[200px] lg:max-w-[250px] xl:max-w-[300px] truncate'>
            {fileUtil.getName(file)}
          </span>
          <XIconButton
            className='rounded-full'
            onClick={() => handleUnselectFile(file)}
          />
        </div>
      ))}
    </div>
  )
}
