import {
  useCreateFolderMutation,
  useGetFoldersQuery,
  useGetUsersMeQuery,
} from '@/api'
import {ROOT_FOLDER} from '@/constants'
import type {Folder, HttpError} from '@/types'
import {Input, Modal, useModal, useToast} from '@gicortex/nucleus'
import {useState} from 'react'

export interface CreatePromptsFolderModalProps {
  folder?: Folder
  pathPrefix: string
}
export const CreatePromptsFolderModal = ({
  folder,
  pathPrefix,
}: CreatePromptsFolderModalProps) => {
  const [submitting, setSubmitting] = useState(false)
  const [name, setName] = useState<string | undefined>(undefined)
  const {showSuccessToast, showErrorToast} = useToast()
  const {closeModal} = useModal()

  const {data: me} = useGetUsersMeQuery()
  const {data: folders} = useGetFoldersQuery({type: 'prompt'})

  const [createFolder] = useCreateFolderMutation()

  if (!me || !folders) {
    return null
  }

  const handleSubmit = async () => {
    try {
      setSubmitting(true)
      await createFolder({
        createFolderModel: {
          bucket: '',
          groupId: me.id,
          path: `${folder?.path ? folder.path : ''}${ROOT_FOLDER.path}${name}`,
          type: 'prompt',
        },
      }).unwrap()
      closeModal()
      showSuccessToast('Folder created successfully')
    } catch (error: unknown) {
      const httpError = error as HttpError
      switch (httpError.status) {
        case 409:
          showErrorToast(httpError.data.detail)
          break
        default:
          showErrorToast('Failed to create folder')
      }
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Modal>
      <Modal.Title>Create Folder</Modal.Title>
      <Input
        label='Folder'
        disabled={true}
        name='folder'
        value={`/${pathPrefix}${folder?.path ? folder.path : ''}`}
      />
      <Input
        autoFocus
        name='name'
        label='Name'
        required
        onChange={(e) => setName(e.target.value)}
      />
      {name === '' && (
        <span className='text-red-500 text-xs'>Name is required</span>
      )}
      <Modal.Footer
        onSubmit={handleSubmit}
        submitButtonProps={{
          className: 'disabled:bg-red-100 enabled:bg-indigo-100',
          closeOnSubmit: false,
          disabled: name === undefined || name === '' || submitting,
          label: 'Create',
        }}
      />
    </Modal>
  )
}
