import {
  type FolderResponseModel,
  type UpdateResourceFolderModel,
  useGetFoldersQuery,
  useGetUserGroupsQuery,
  useUpdateResourceFolderInBatchMutation,
} from '@/api'
import {FolderType} from '@/constants'
import type {DataBucketFile, Folder, HttpError} from '@/types'
import {fileUtil} from '@/utils/file'
import {Input, Listbox, Modal, useModal, useToast} from '@gicortex/nucleus'
import {useEffect, useState} from 'react'

export interface MoveFileModalProps {
  file: DataBucketFile
  pathPrefix: string
}
export const MoveFileModal = ({file, pathPrefix}: MoveFileModalProps) => {
  const [rootFolder, setRootFolder] = useState<Folder>()
  const [options, setOptions] = useState<FolderResponseModel[]>()
  const [submitting, setSubmitting] = useState(false)
  const [selectedFolder, setSelectedFolder] = useState<FolderResponseModel>()

  const {data: folders} = useGetFoldersQuery({type: FolderType.FILES})
  const {data: userGroups} = useGetUserGroupsQuery()

  const [moveToFolder] = useUpdateResourceFolderInBatchMutation()

  const {showSuccessToast, showErrorToast} = useToast()
  const {closeModal} = useModal()

  useEffect(() => {
    if (userGroups) {
      const id =
        pathPrefix === 'Your Files'
          ? userGroups.find((group) => group.name === 'Personal')?.id // same as me?.id
          : userGroups.find((group) => group.name === pathPrefix)?.id
      setRootFolder({
        id,
        path: `${pathPrefix}/`,
      } as Folder)
    }
  }, [pathPrefix, userGroups])
  useEffect(() => {
    if (rootFolder && folders) {
      const fileGroupId = file.path.split('/')[1]
      const options: FolderResponseModel[] = folders
        .filter((folder) => {
          return (
            folder.groupId === fileGroupId &&
            !file.tags?.some(
              (tag) => tag.key === 'folder' && tag.value === folder.path,
            )
          )
        })
        .map((folder) => {
          const rootFolderName = userGroups?.find(
            (group) => group.id === folder.groupId,
          )?.name
          const path = `/${rootFolderName === 'Personal' ? 'Your Files' : rootFolderName}${folder.path}`
          return {
            ...folder,
            path,
          }
        })
      const tags = file.tags?.filter((tag) => tag.key === 'folder') ?? []
      if (tags.length > 0) {
        options.unshift(rootFolder as FolderResponseModel)
      }
      setOptions(options)
      setSelectedFolder(options[0])
    }
  }, [file, folders, rootFolder, userGroups])

  const handleSubmit = async () => {
    if (!selectedFolder) throw new Error('Selected folder is required')
    try {
      setSubmitting(true)
      const addActions: UpdateResourceFolderModel[] = []
      let removeActions: UpdateResourceFolderModel[] = []
      if (
        // moving to the root folder
        selectedFolder.id === rootFolder?.id &&
        // file has tags
        file.tags &&
        file.tags.length > 0
      ) {
        removeActions = file.tags
          .filter((tag) => tag.key === 'folder')
          .map((tag) => ({
            action: 'remove',
            folderId: folders?.find((folder) => folder.path === tag.value)?.id,
            filePath: fileUtil.getSourceId(file),
          }))
          .filter((action) => action.folderId) as UpdateResourceFolderModel[]
      } else {
        // moving to a nested folder
        addActions.push({
          action: 'add',
          filePath: fileUtil.getSourceId(file),
          folderId: selectedFolder.id,
        })
        removeActions = file.tags
          ?.filter((tag) => tag.key === 'folder')
          .map((tag) => ({
            action: 'remove',
            folderId: folders?.find((folder) => folder.path === tag.value)?.id,
            filePath: fileUtil.getSourceId(file),
          })) as UpdateResourceFolderModel[]
      }
      await moveToFolder({
        body: [...addActions, ...removeActions],
      }).unwrap()
      closeModal()
      showSuccessToast('File moved successfully')
    } catch (error: unknown) {
      const httpError = error as HttpError
      switch (httpError.status) {
        default:
          showErrorToast('Failed to move file')
      }
    } finally {
      setSubmitting(false)
    }
  }

  if (!options) {
    return null
  }

  return (
    <Modal>
      <Modal.Title>Move To Folder</Modal.Title>
      <Input
        defaultValue={fileUtil.getName(file)}
        disabled
        label='File'
        name='file'
      />
      <Listbox
        label='Folder'
        name='folder'
        defaultSelected={options[0]?.id}
        optionLabelKey='path'
        optionValueKey='id'
        options={options}
        onChange={(option) => setSelectedFolder(option as FolderResponseModel)}
      />
      <Modal.Footer
        onSubmit={handleSubmit}
        submitButtonProps={{
          className: 'disabled:bg-red-100',
          disabled: submitting,
          label: 'Move',
        }}
      />
    </Modal>
  )
}
