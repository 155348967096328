import {SidebarContent} from '@/layout/sidebar-content'
import {YourPrompts} from './your-prompts'

export const Prompts = () => {
  return (
    <SidebarContent className='gap-10'>
      <YourPrompts />
    </SidebarContent>
  )
}
