import {useGetAllPromptsQuery, useGetFoldersQuery} from '@/api'
import {CreateButton} from '@/components/create-button'
import {CreateFolderButton} from '@/components/create-folder-button'
import {TransitionState} from '@/components/transition-state'
import {Tree} from '@/components/tree'
import {FolderType} from '@/constants'
import {SidebarTitle} from '@/layout/sidebar-title'
import type {Folder} from '@/types'
import {folderUtil} from '@/utils/folder'
import {Col} from '@gicortex/nucleus'
import {useEffect, useState} from 'react'
import {CreatePromptModal} from './create-prompt-modal'
import {CreatePromptsFolderModal} from './create-prompts-folder-modal'

const TITLE = 'Your Prompts'

export const YourPrompts = () => {
  const [folder, setFolder] = useState<Folder>()

  const {
    data: folders,
    error: foldersError,
    isLoading: foldersIsLoading,
  } = useGetFoldersQuery({type: FolderType.PROMPTS})
  const {
    data: prompts,
    error: promptsError,
    isLoading: promptsIsLoading,
  } = useGetAllPromptsQuery()

  useEffect(() => {
    if (folders && prompts) {
      setFolder(
        folderUtil.toTree({
          rootFolderName: TITLE,
          folderResponses: folders,
          responseEntities: prompts,
        }),
      )
    }
  }, [folders, prompts])

  return (
    <Col>
      <SidebarTitle title={TITLE}>
        <CreateButton
          modal={<CreatePromptModal pathPrefix={TITLE} />}
          singularEntityName='Prompt'
        />
        <CreateFolderButton
          modal={<CreatePromptsFolderModal pathPrefix={TITLE} />}
        />
      </SidebarTitle>
      <TransitionState
        error={foldersError || promptsError}
        isLoading={foldersIsLoading || promptsIsLoading}
      >
        <Tree
          folder={folder}
          pathPrefix={TITLE}
        />
      </TransitionState>
    </Col>
  )
}
