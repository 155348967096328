import {useDeletePromptByIdMutation} from '@/api'
import type {Prompt} from '@/types'
import {DeleteModalButton, useToast} from '@gicortex/nucleus'

const ACTION_TITLE = 'Delete Prompt'

export interface DeletePromptButtonProps {
  prompt: Prompt
}

export const DeletePromptButton = ({prompt}: DeletePromptButtonProps) => {
  const [deletePrompt] = useDeletePromptByIdMutation()
  const {showSuccessToast, showErrorToast} = useToast()

  const handleDelete = async () => {
    try {
      await deletePrompt({id: prompt.id})
      showSuccessToast('Prompt deleted successfully')
    } catch (error) {
      showErrorToast('Failed to delete prompt')
      console.error(error)
    }
  }

  return (
    <DeleteModalButton
      className='hidden p-0 group-hover:block'
      content={
        <p>
          Are you sure you want to delete <strong>{prompt.name}</strong>?
        </p>
      }
      title={ACTION_TITLE}
      tooltip={ACTION_TITLE}
      onSubmit={handleDelete}
    />
  )
}
