import {Sidebar} from '@/layout/sidebar'
import {PromptProvider} from '@/providers/prompt'
import type {RootState} from '@/types'
import {PlaceCenter, ResizeableArea} from '@gicortex/nucleus'
import type React from 'react'
import {useSelector} from 'react-redux'
import {MainPane} from './main-pane'
import {SplitPane} from './split-pane'

interface LayoutProps {
  center?: boolean
  children: React.ReactNode
}

export const Layout = ({center, children}: LayoutProps) => {
  const layout = useSelector((state: RootState) => state.layout)

  return (
    <PromptProvider>
      <div className='bg-gray-200 h-lvh p-2'>
        <ResizeableArea
          separator={false}
          vertical={false}
        >
          <ResizeableArea.Pane
            maxSize={240}
            minSize={240}
            visible={layout.showSidebar}
          >
            <Sidebar />
          </ResizeableArea.Pane>
          <ResizeableArea vertical={false}>
            <MainPane>
              {center ? <PlaceCenter>{children}</PlaceCenter> : children}
            </MainPane>
            {layout.splitPaneTabs.length > 0 && (
              <ResizeableArea.Pane
                className='z-0'
                minSize={240}
                snap={false}
              >
                <SplitPane />
              </ResizeableArea.Pane>
            )}
          </ResizeableArea>
        </ResizeableArea>
      </div>
    </PromptProvider>
  )
}
