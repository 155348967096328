import {
  type Message,
  useGetDatabucketFilesQuery,
  useGetUsersMeQuery,
} from '@/api'
import {ROLE} from '@/constants'
import {Messages} from '@/features/chat/messages'
import {NewChatButton} from '@/features/chat/new-chat-button'
import {PromptInput} from '@/features/chat/prompt-input'
import {useUploadFiles} from '@/hooks/use-upload-files'
import {ToggleSidebarButton} from '@/layout/toggle-sidebar-button'
import {currentSlice} from '@/store/current'
import type {Folder, Role, RootState} from '@/types'
import {
  DropzoneOverlay,
  Row,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  useDropzone,
} from '@gicortex/nucleus'
import {useEffect, useMemo, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

const YOUR_FILES_ROOT_FOLDER: Folder = {
  children: [],
  name: 'Personal',
  path: 'Your Files/',
}

export const Chat = () => {
  const layout = useSelector((state: RootState) => state.layout)
  const dispatch = useDispatch()
  const {uploadFiles} = useUploadFiles()
  const {messages} = useSelector((state: RootState) => state.current)
  const messagesRef = useRef<HTMLDivElement | null>(null)

  const {data: me} = useGetUsersMeQuery()

  useEffect(() => {
    const handleScroll = () => {
      if (messagesRef.current) {
        messagesRef.current.scrollTo({
          top: messagesRef.current.scrollHeight,
          behavior: 'smooth',
        })
      }
    }
    const lastMessage = messages[messages.length - 1] as Message & {
      source: Role
    }
    if (lastMessage?.source === ROLE.USER) {
      handleScroll()
    }
  }, [messages])

  // TODO @michael Workaround to automatically select the uploaded files since
  // upload does not return them
  const {data: files = []} = useGetDatabucketFilesQuery()
  const [selectedFiles, setSelectedFiles] = useState<File[]>([])

  const onDrop = async (files: File[]) => {
    if (!me) throw new Error('Me is required')
    const folder: Folder = {
      ...YOUR_FILES_ROOT_FOLDER,
      id: me?.id,
    }
    await uploadFiles({files, folder})
    setSelectedFiles(files)
  }

  // TODO: workarounds to automatically select the uploaded files since upload
  // does not return them. Only try to upload once all pending files are
  // returned by getFilesQuery
  const shouldSelectFiles = useMemo(() => {
    return (
      selectedFiles &&
      selectedFiles.length > 0 &&
      selectedFiles.every((file) =>
        files.find(
          (f) => f.file === `/${YOUR_FILES_ROOT_FOLDER.name}/${file.name}`,
        ),
      )
    )
  }, [files, selectedFiles])

  useEffect(() => {
    if (shouldSelectFiles) {
      for (const file of selectedFiles) {
        // NOTE @michael Hardcoded to only expect uploads to 'Your Files' group
        // for now
        const match = files.find(
          (f) => f.file === `/${YOUR_FILES_ROOT_FOLDER.name}/${file.name}`,
        )
        if (match) {
          const [_, groupId, fileName] = match.file.split('/') // file property is string of form "/groupId/fileName"
          dispatch(
            currentSlice.actions.selectFile({
              bucketName: match.bucket,
              groupId,
              id: match.path,
              file: fileName,
              path: match.path,
            }),
          )
        }
      }
      setSelectedFiles([])
    }
  }, [files, selectedFiles, dispatch, shouldSelectFiles])

  const {isDragActive, handleDragOver, handleDragLeave, handleDrop} =
    useDropzone({onDrop})

  return (
    <TabGroup
      className='grid grid-rows-[auto_1fr_auto] min-w-80 overflow-y-auto w-full'
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <DropzoneOverlay visible={isDragActive} />
      <TabList className='border-b flex'>
        {!layout.showSidebar && (
          <Row className='bg-gray-100 border-r p-2 rounded-tl-lg'>
            <ToggleSidebarButton />
          </Row>
        )}
        <Row className='p-2 w-full'>
          <NewChatButton />
        </Row>
      </TabList>
      <TabPanels className='overflow-y-auto mt-0'>
        <TabPanel className='h-full !py-0'>
          <div
            className='h-full overflow-y-auto'
            ref={messagesRef}
          >
            <Messages />
          </div>
        </TabPanel>
      </TabPanels>
      <PromptInput />
    </TabGroup>
  )
}
