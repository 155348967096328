import {ButtonGroup} from '@/components/button-group'
import {MoveToFolderButton} from '@/components/move-to-folder-button'
import {FileProcessingStatus, FolderType} from '@/constants'
import {currentSlice} from '@/store/current'
import type {DataBucketFile, RootState} from '@/types'
import {fileUtil} from '@/utils/file'
import {
  Icon,
  Row,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  cn,
} from '@gicortex/nucleus'
import {Dot} from 'lucide-react'
import {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {DeleteFileButton} from './delete-file-button'
import {MoveFileModal} from './move-file-modal'
import {PreviewButton} from './preview-button'
import {ReprocessFileButton} from './reprocess-button'
export interface FileItemProps {
  file: DataBucketFile
  pathPrefix: string
}
export const FileItem = ({file, pathPrefix}: FileItemProps) => {
  const name = fileUtil.removeUuidPrefix(fileUtil.getName(file))
  const [reprocessFile, setReprocessFile] = useState(false)
  const {reprocessFiles} = useSelector((state: RootState) => state.current)
  const dispatch = useDispatch()

  const getFileStatus = ({file}: {file: DataBucketFile}) => {
    if (file.status !== FileProcessingStatus.SUCCESS) {
      if (reprocessFiles.isReprocessing || reprocessFile) {
        return fileUtil.getStatusProps(FileProcessingStatus.PENDING)
      }
      if (reprocessFiles.hasAttemptedReprocessing) {
        return fileUtil.getStatusProps(FileProcessingStatus.FAILURE)
      }
    }
    return fileUtil.getStatusProps(file.status as FileProcessingStatus)
  }

  const status = getFileStatus({file})

  const handleSelect = () => {
    dispatch(currentSlice.actions.selectFile(file))
  }

  return (
    <Row
      className={cn(
        'gap-2 group justify-between p-1 rounded-lg text-sm',
        'hover:bg-indigo-100 hover:cursor-pointer',
        'active:bg-indigo-50',
      )}
      key={file.id}
      onClick={handleSelect}
    >
      <Row className='truncate'>
        <Icon
          className={cn('mr-1 pointer-events-auto', status.color)}
          component={Dot}
          strokeWidth='12'
          tooltip={status.description}
        />
        <Tooltip>
          <TooltipTrigger asChild={true}>
            <p className='truncate'>{name}</p>
          </TooltipTrigger>
          <TooltipContent>{name}</TooltipContent>
        </Tooltip>
      </Row>
      <ButtonGroup
        className={cn(
          'align-center hidden ml-auto space-x-2',
          'group-hover:flex',
          '[&_button]:p-0',
        )}
      >
        {status.name !== FileProcessingStatus.SUCCESS && (
          <ReprocessFileButton
            setReprocessing={setReprocessFile}
            file={file}
          />
        )}
        <PreviewButton file={file} />
        <MoveToFolderButton
          folderType={FolderType.FILES}
          modal={
            <MoveFileModal
              file={file}
              pathPrefix={pathPrefix}
            />
          }
          pathPrefix={pathPrefix}
        />
        <DeleteFileButton file={file} />
      </ButtonGroup>
    </Row>
  )
}
