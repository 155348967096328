import {ButtonGroup} from '@/components/button-group'
import {Row} from '@gicortex/nucleus'

export interface SidebarTitleProps {
  title: string
  children?: React.ReactNode
}

export const SidebarTitle = ({children, title}: SidebarTitleProps) => (
  <Row className='justify-between'>
    <h6 className='p-2'>{title}</h6>
    <ButtonGroup className='pr-2 space-x-2 [&_button]:p-0'>
      {children}
    </ButtonGroup>
  </Row>
)
