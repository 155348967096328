import {useDeleteDatabucketFileMutation} from '@/api'
import type {DataBucketFile, HttpError} from '@/types'
import {fileUtil} from '@/utils/file'
import {DeleteModalButton, useToast} from '@gicortex/nucleus'
import React from 'react'

export interface DeleteFileButtonProps {
  file: DataBucketFile
}

export const DeleteFileButton = ({file}: DeleteFileButtonProps) => {
  const [, setSubmitting] = React.useState(false)

  const {showSuccessToast, showErrorToast} = useToast()

  const [deleteItem] = useDeleteDatabucketFileMutation()

  const handleDelete = async (item: DataBucketFile) => {
    try {
      setSubmitting(true)
      await deleteItem({
        databucketName: item.bucket,
        dataBucketRequest: {path: item.path},
      }).unwrap()
      showSuccessToast('File deleted successfully')
    } catch (error) {
      const httpError = error as HttpError
      switch (httpError.status) {
        default:
          showErrorToast('Failed to delete file')
      }
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <DeleteModalButton
      className='p-0 hidden group-hover:block'
      title='Delete'
      content={
        <p>
          Are you sure you want to delete{' '}
          <strong>{fileUtil.getName(file)}</strong>?
        </p>
      }
      tooltip='Delete'
      onSubmit={() => handleDelete(file)}
    />
  )
}
