import {SelectedFiles} from '@/components/selected-files'
import {SendPromptButton} from '@/components/send-prompt-button'
import {APP_STATE} from '@/constants'
import {usePrompts} from '@/hooks/use-prompts'
import type {RootState} from '@/types'
import {Col, Row, Textarea} from '@gicortex/nucleus'
import {useSelector} from 'react-redux'

export const PromptInput = () => {
  const uploadingFiles = useSelector(
    (state: RootState) => state.current.uploadingFiles,
  )
  const current = useSelector((state: RootState) => state.current)
  const {sendPrompt, setPrompt, prompt} = usePrompts()

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const prompt = e.target.value
    setPrompt(prompt)
  }
  const hasText = /\S/.test(prompt)
  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && e.shiftKey) {
      return
    }
    if (current.appState === APP_STATE.WAITING_FOR_AGENT_RESPONSE) return
    if (e.key === 'Enter' && hasText) {
      e.preventDefault()
      sendPrompt({prompt})
    }
  }

  return (
    <Col className='gap-2 items-center max-w-screen-md mx-auto w-full'>
      <SelectedFiles />
      <div className='px-8 w-full'>
        <Textarea
          onKeyDown={handleKeyDown}
          className='self-center w-full'
          data-prompt-input
          name='prompt'
          onChange={handleChange}
          textAreaClassName='bg-gray-50 border-0 focus-visible:outline-none p-4 resize-none rounded-none !rounded-tl-xl !rounded-tr-xl w-full resize-y min-h-[45px]'
          value={prompt}
        />
        <Row className='bg-gray-100 gap-2 justify-end justify-self-center items-end p-2 rounded-bl-xl rounded-br-xl w-full '>
          <SendPromptButton disabled={!hasText || uploadingFiles.length > 0} />
        </Row>
      </div>
    </Col>
  )
}
