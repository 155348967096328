import {useCreateNewPromptMutation} from '@/api'
import type {Folder, HttpError} from '@/types'
import {
  Form,
  FormModal,
  nonEmptyString,
  optionalString,
  useModal,
  useToast,
} from '@gicortex/nucleus'
import {useState} from 'react'

export interface CreatePromptModalProps {
  folder?: Folder
  pathPrefix: string
}
export const CreatePromptModal = ({
  folder,
  pathPrefix,
}: CreatePromptModalProps) => {
  const [submitting, setSubmitting] = useState(false)

  const [createPrompt] = useCreateNewPromptMutation()
  const {showSuccessToast, showErrorToast} = useToast()
  const {closeModal} = useModal()

  const handleSubmit = async ({
    name,
    description,
    prompt,
  }: {
    name: string
    description: string
    prompt: string
  }) => {
    try {
      setSubmitting(true)
      const result = await createPrompt({
        createPromptModel: {
          folderId: folder?.id,
          name,
          description,
          prompt,
        },
      }).unwrap()
      closeModal()
      showSuccessToast('Prompt created successfully')
      return result
    } catch (error: unknown) {
      const httpError = error as HttpError
      switch (httpError.status) {
        case 409:
          showErrorToast(httpError.data.detail)
          break
        default:
          showErrorToast('Failed to create Prompt')
      }
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <FormModal
      modalProps={{size: 'm'}}
      closeOnSubmit={false}
      title='Create Prompt'
      // @ts-ignore
      onSubmit={handleSubmit}
      defaultValues={{
        name: '',
        description: '',
        prompt: '',
      }}
      schema={() => ({
        name: nonEmptyString('Name'),
        description: optionalString(),
        prompt: nonEmptyString('Prompt'),
      })}
      submitButtonProps={{
        label: 'Create',
        disabled: submitting,
        loading: submitting,
      }}
    >
      <Form.Input
        disabled
        label='Folder'
        name='folder'
        value={`/${pathPrefix}${folder?.path ? folder.path : ''}`}
      />
      <Form.Input
        autoFocus
        name='name'
        label='Name'
        type='text'
      />
      <Form.Textarea
        name='description'
        label='Description'
        rows={3}
      />
      <Form.Textarea
        label='Prompt'
        name='prompt'
        rows={10}
      />
    </FormModal>
  )
}
