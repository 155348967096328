import {
  type GetDatabucketFilesApiResponse,
  type GetFoldersApiResponse,
  useGetDatabucketFilesQuery,
  useGetFoldersQuery,
  useGetUserGroupsQuery,
} from '@/api'
import {TransitionState} from '@/components/transition-state'
import {Tree} from '@/components/tree'
import {FolderType} from '@/constants'
import {SidebarTitle} from '@/layout/sidebar-title'
import type {Folder} from '@/types'
import {folderUtil} from '@/utils/folder'
import {Col} from '@gicortex/nucleus'
import {useEffect, useState} from 'react'

const TITLE = 'Shared Files'

export interface SharedFilesProps {
  folders?: GetFoldersApiResponse
  files?: GetDatabucketFilesApiResponse
}
export const SharedFiles = ({folders, files}: SharedFilesProps) => {
  const [sharedFolders, setSharedFolders] = useState<Folder[]>([])

  const {
    data: userGroups,
    error: userGroupsError,
    isLoading: userGroupsIsLoading,
  } = useGetUserGroupsQuery()
  const {error: foldersError, isLoading: foldersIsLoading} = useGetFoldersQuery(
    {type: FolderType.FILES},
  )
  const {error: filesError, isLoading: filesIsLoading} =
    useGetDatabucketFilesQuery()

  useEffect(() => {
    if (userGroups && folders && files) {
      const sharedFolderNames = userGroups.filter(
        (sharedFolder) => sharedFolder.name !== 'Personal',
      )
      const trees: Folder[] = []
      for (const sharedFolderName of sharedFolderNames) {
        const sharedFolderFiles = files.filter((file) =>
          file.file.startsWith(`/${sharedFolderName.name}/`),
        )
        const sharedFolder = folderUtil.toTree({
          rootFolderName: sharedFolderName.name,
          folderResponses: folders.filter(
            (folder) => folder.groupId === sharedFolderName.id,
          ),
          responseEntities: sharedFolderFiles.filter(
            (response) => response.file.split('/')[1] === sharedFolderName.name,
          ),
        })
        sharedFolder.groupId = sharedFolderName.id
        sharedFolder.id = sharedFolderName.id
        sharedFolder.isDeletable = false
        sharedFolder.path = `/${sharedFolderName.name}`
        sharedFolder.type = FolderType.FILES
        trees.push(sharedFolder)
      }
      setSharedFolders(trees)
    }
  }, [folders, files, userGroups])

  return (
    <Col>
      <SidebarTitle title={TITLE} />
      <TransitionState
        error={userGroupsError || foldersError || filesError}
        isLoading={userGroupsIsLoading || foldersIsLoading || filesIsLoading}
      >
        {sharedFolders?.map((sharedFolder) => (
          <Tree
            key={sharedFolder.id}
            folder={{...sharedFolder, children: [sharedFolder]}}
            pathPrefix={sharedFolder.name}
          />
        ))}
      </TransitionState>
    </Col>
  )
}
