import {randomUtil} from '@/utils/random'
import {Icon, Loader, Row} from '@gicortex/nucleus'
import {Bot} from 'lucide-react'
import {useEffect, useState} from 'react'

const MESSAGE_ROTATION_INTERVAL = 5000
const WAITING_MESSAGES = [
  "Hang tight! I'm processing your request.",
  'Just a moment while I gather the information you need.',
  "Thanks for your patience! I'm almost there.",
  'Loading your answer... please hold on.',
  "Working on it! I'll be with you shortly.",
]

export const AgentWaitingIndicator = () => {
  const [index, setIndex] = useState(randomUtil.arrayIndex(WAITING_MESSAGES))
  const [waitingMessage, setWaitingMessage] = useState(WAITING_MESSAGES[index])

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex(randomUtil.arrayIndex(WAITING_MESSAGES))
    }, MESSAGE_ROTATION_INTERVAL)

    return () => clearInterval(intervalId)
  }, [])

  useEffect(() => {
    setWaitingMessage(WAITING_MESSAGES[index])
  }, [index])

  if (!waitingMessage) {
    return null
  }

  return (
    <Row className='gap-4 items-start'>
      <Icon
        component={Bot}
        size='m'
      />
      <Loader className='h-6 w-6' />
      <p>{waitingMessage}</p>
    </Row>
  )
}
