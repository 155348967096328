import {
  useCreateDatabucketFileIndexMutation,
  useGetDatabucketFilesQuery,
} from '@/api'
import type {DataBucketFile} from '@/types'
import {RefreshIconButton} from '@gicortex/nucleus'

export interface ReprocessFileButtonProps {
  file: DataBucketFile
  setReprocessing?: (reprocessing: boolean) => void
}

export const ReprocessFileButton = ({
  file,
  setReprocessing,
}: ReprocessFileButtonProps) => {
  const {refetch} = useGetDatabucketFilesQuery()

  const [retriggerFile, {isLoading}] = useCreateDatabucketFileIndexMutation()

  return (
    <RefreshIconButton
      isFetching={isLoading}
      refetch={async () => {
        setReprocessing?.(true)
        await retriggerFile({
          databucketName: file.bucket,
          dataBucketFileIndexRequest: {path: file.path},
        }).unwrap()
        refetch()
        setReprocessing?.(false)
      }}
      tooltip='Reprocess'
      className='p-0 hidden group-hover:block'
    />
  )
}
