import {useGetDatabucketFilesQuery, useGetFoldersQuery} from '@/api'
import {FolderType} from '@/constants'
import {useReprocessFiles} from '@/hooks/use-reprocess-files'
import {SidebarContent} from '@/layout/sidebar-content'
import {SharedFiles} from './shared-files'
import {YourFiles} from './your-files'

export const Files = () => {
  const {data: folders} = useGetFoldersQuery({type: FolderType.FILES})
  const {data: files} = useGetDatabucketFilesQuery()

  useReprocessFiles()

  return (
    <SidebarContent className='gap-10'>
      <YourFiles
        folders={folders}
        files={files}
      />
      <SharedFiles
        folders={folders}
        files={files}
      />
    </SidebarContent>
  )
}
